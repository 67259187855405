import React from 'react';
import classNames from 'classnames';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Image from '../Image';
import CTAButton from '../CTAButton/CTAButton';

const styles = theme =>
  createStyles({
    root: {
    maxHeight: '100%',
    },
    innerImageWrap: {
      width: '100%',
      height: '100%',
      willChange: 'transform',
      border: 0,
    },
    image: {
      width: '100%',
      height: '100%',
      maxHeight: '660px',
      objectFit: 'cover',
    },
    copy: {
      padding: '0 50px',
      minHeight: '48px',
      maxWidth: 340,
      margin: '0 auto',
    },
    invertedFontColor: {
      color: '#fff',
    },
    largeImage: {
      width: '100%',
      height: '435px',
      objectFit: 'cover', 
      [theme.breakpoints.up(767)]: {
        height: '660px'
      },       
    },

    mediumImage: {
      width: '100%',
      height: '435px',
      objectFit: 'cover',       
    },

        ctaWrap: {
      backgroundColor: theme.palette.primary.main,
      justifyContent: 'center',
      alignItems: "center",
      flexDirection: "column",
      padding: "15px 5px",
      [theme.breakpoints.up(380)]: {
        padding: "15px 10px",
        flexDirection: "row",
        textAlign: "center",
        justifyContent: "center",
      },
      [theme.breakpoints.up(960)]: {
        textAlign: "center",
        justifyContent: "center",
        padding: "30px 15px"
      },

    },
    ctaTitle: {
      fontSize: 16,
      fontWeight: '300',
      paddingBottom: '10px',
      textAlign: 'center',
      [theme.breakpoints.up(350)]: {
        fontSize: 17,
      },
      [theme.breakpoints.up(380)]: {
        maxWidth: '260px',
        paddingBottom: 0,
        paddingRight: '25px',
        textAlign: 'left',
      },
      [theme.breakpoints.up(767)]: {
        maxWidth: '100%',
        textAlign: 'center',
      },
      [theme.breakpoints.up(960)]: {
        fontSize: 32,
        letterSpacing: '-0.3px',
        paddingBottom: '4px'
      },
    }
  });

const CtaCarousel = ({ classes, imagePath, inverted = false, largeImage = false, mediumImage = false, ctaTitle, ctaText, ctaHref }) => (
  
  <Grid
    container
    item
    justify="center"
    direction="column"
    className={classNames(classes.root, {
      [classes.invertedBackground]: inverted,
    })}
  > 

    <Grid container item justify="center">
      <Grid item className={classNames(classes.innerImageWrap, { [classes.largeImage]: largeImage }, { [classes.mediumImage]: mediumImage })}>
        <Image className={classNames(classes.image)} relativePath={imagePath} />
      </Grid>

    </Grid>

  <Grid
    container
    className ={classes.ctaWrap}
  >

    <Typography
      variant="h2"
      color="secondary"
      className={classes.ctaTitle}
    > 
    {ctaTitle} 
    </Typography>

    <CTAButton
        ctaText={ctaText || 'Learn More'}
        ctaHref={ctaHref}
        className={classes.ctaButton}
        tertiary
    >
    </CTAButton>

  </Grid>

  </Grid>

);

export default withStyles(styles, { withTheme: true })(CtaCarousel);