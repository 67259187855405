
import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import CardImage from '../CardImage';
import CTAButton from '../CTAButton/CTAButton';

const styles = theme =>
  createStyles({

    ctaWrap: {
      padding: "10px",
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.up(1050)]: { 
        flexDirection: 'row',
      },
    },

    cardContent: {
      backgroundColor: theme.palette.primary.main,
      height: '100%',
    },

    ctaTitle: {
      fontSize: 18,
      paddingRight: 0,
      // maxWidth: '170px',
      fontWeight: '300',
      paddingBottom: 10,
      [theme.breakpoints.up(960)]: { 
        paddingRight: '10px',
        fontSize: 18,
      },
      [theme.breakpoints.up(1050)]: { 
        paddingBottom: 0
      },
    },

    cardImageWrap: {
      position: 'relative',
      zIndex: 1,
      top: '0',
      left: '0',
    },

    cardImage: {
      width: "100%",
      height: '400px'
    },

    cardWrap: {
      [theme.breakpoints.up(767)]: {
        flex: '0 0 50%',
        maxWidth: '50%',
        padding: 5,
        marginBottom: 0
      },

      // [theme.breakpoints.up(960)]: {
      //   flex: '0 0 33.33%',
      //   maxWidth: '33.33%',
      // },
    },

  });

  const ProjectCard = ({classes,cardImage,ctaTitle,ctaText,ctaHref}) => (

    <Grid
    container  
    direction="column"
    className= {classes.cardWrap}
>

    <Grid className={classes.cardContent} >

    {/* CardIMG */}
    {cardImage && (
        <Grid item className={classes.cardImageWrap}>
            <CardImage relativePath={cardImage} className={classes.cardImage}></CardImage>
        </Grid>
    )}

        {/* CardCTA  */}
         <Grid
            container
            className ={classes.ctaWrap}
        >

            <Typography
              variant="body2"
              color="secondary"
              className={classes.ctaTitle}
            > 
            {ctaTitle} 
            </Typography>

            <CTAButton
              ctaText={ctaText}
              ctaHref={ctaHref}
              className={classes.ctaButton}
              small
            >
            </CTAButton>

        </Grid>

      </Grid>
   
    </Grid>


  )
  export default withStyles(styles, { withTheme: true })(ProjectCard);