import React from 'react';
import classNames from 'classnames';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CtaCarousel from './CtaCarousel'
import { autoPlay } from 'react-swipeable-views-utils';
import SwipeableViews from 'react-swipeable-views';
import Animated from 'animated/lib/targets/react-dom';
import Stepper2 from './Stepper2';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = theme =>
  createStyles({
    root: {
      height: '100%',
      justifyContent: 'center',
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        height: '100%',
      },
    },
    invertedBackground: {
    //   backgroundColor: '#181A38',
    },
    slide: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      display: 'flex',
      
    },
    swipableViewsWrap: {
      width: '100vw',
      [theme.breakpoints.up(960)]: {
        width: '100%',
      },
    },

    stepperWrap: {
        position: 'absolute',
        bottom: '105px',
        left: '0',
        right: '0',
        [theme.breakpoints.up(960)]: {
          bottom: '150px'
        },
    }
  });

class HeroCarouselSlider extends React.Component {
  constructor() {
    super();

    this.state = {
      activeStep: 0,
      position: new Animated.Value(0),
    };
  }

  handleStepChange = nextStep => {
    Animated.spring(this.state.position, { toValue: nextStep }).start();

    this.setState({ activeStep: nextStep });
  };

  handleSwitch = (index, type) => {
    if (type === 'end') {
      Animated.spring(this.state.position, { toValue: index }).start();
      return;
    }

    Animated.spring(this.state.position, { toValue: index }).start();
  };

  render() {
    const { items, inverted, largeImage,mediumImage, classes, theme } = this.props;
    const { activeStep, position } = this.state;

    return (
      <Grid
        item
        container
        direction="column"
        justify="center"
        alignItems="center"
        alignContent="center"
        className={classNames(classes.root, {
          [classes.invertedBackground]: inverted,
        })}
      >
        <AutoPlaySwipeableViews
          className={classes.swipableViewsWrap}
          interval={4000}
          springConfig={{
            duration: '800ms',
            easeFunction: 'cubic-bezier(0.47, 0, 0.745, 0.715)',
            delay: '0s',
          }}
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={this.handleStepChange}
          onSwitching={this.handleSwitch}
          enableMouseEvents
          threshold={5}
        >
          {items.map((step, currentIndex) => {
            const inputRange = items.map((_, i) => i);
            const opacity = position.interpolate({
              inputRange,
              outputRange: inputRange.map(i => {
                return currentIndex === i ? 1 : 1;
              }),
            });

              return (
              <Animated.div 
                key={String(currentIndex)}
                style={Object.assign(
                  {
                    opacity,
                    // transform: [{ scale }, { translateX }/*, { rotateY }*/],
                  },
                  styles.slide
                )}
              >
                <CtaCarousel
                  inverted={inverted}
                  largeImage={largeImage}
                  mediumImage={mediumImage}
                  {...step}
                />
              </Animated.div>
            );
          })}

        </AutoPlaySwipeableViews>
        <Grid item className={classes.stepperWrap}>
          <Stepper2
            steps={items.length}
            activeStep={activeStep}
            inverted={inverted}
            onDotClick={this.handleStepChange}
            
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HeroCarouselSlider);
