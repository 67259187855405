import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

//Images
import {  DrainageServiceImages,
          OurProjectsImages } 
from '../imageURLs';

//Data
import listItems from '../../data/drainage/key-benefits.json';

import CenterContentNarrow from '../components/CenterContentNarrow';
import ProjectCard from '../components/singleproject/ProjectCard';
import SEO from '../components/SEO';
import { withQuoteWizard } from '../components/withQuoteWizard';
import CtaBlock from '../components/CtaBlock';
import CenterContentMain from '../components/CenterContentMain';
import CTAButton from '../components/CTAButton/CTAButton';
import InnerImage from '../components/InnerImage/innerImage';
import SectionTitle from '../components/SectionTitle';
import ListItems from '../components/singleproject/listItems';
import Description from '../components/Description';
import TitleSmaller from '../components/TitleSmaller';
import ThumbImages from '../components/ThumbImages';
import ServiceIntroSection from '../components/ServiceIntroSection';
import CtaCarouselSlider from '../components/Carousel/CtaCarouselSlider';
import ReactPlayer from 'react-player/lazy';
import VisibilitySensor from 'react-visibility-sensor';

const videoUrl="https://www.youtube.com/watch?v=2YankBSoP1U";

const projectSliderItems = [
  { 
    id: '1',
    ctaTitle: "O'Callaghan Park, Zillmere",
    ctaHref:'/o-callaghan-park',
    ctaText: 'Learn more',
    imagePath: OurProjectsImages.image2,
  },
  { 
    id: '2',
    ctaTitle: "Dolphins Stadium, Redcliffe",
    ctaHref:'/dolphins-stadium',
    ctaText: 'Learn more',
    imagePath: OurProjectsImages.image1,
    },
  { 
    id: '3',
    ctaTitle: "Mitchelton Park, Gaythorne",
    ctaHref:'/mitchelton-park',
    ctaText: 'Learn more',
    imagePath: OurProjectsImages.image4,
  },
  { 
    id: '4',
    ctaTitle: "South Pine Sports Complex, Moreton Bay Region",
    ctaHref:'/south-pine-sports-complex',
    ctaText: 'Learn more',
    imagePath: OurProjectsImages.image5,
  },
];  

const styles = theme =>
  createStyles({

    cardsWrap: {
      marginTop: 30,
      [theme.breakpoints.up(767)]: { 
        flexDirection: "row"
      },
    },

    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },

    imageSection: {
      [theme.breakpoints.up(960)]: {
        padding: '40px 0 50px 0',
      },
    },

    twoColumnsWrap: {
      alignItems: 'flex-start',
      paddingTop: 22,
      paddingBottom: 20,
      flexWrap: 'wrap',
      [theme.breakpoints.up(960)]: {
        flexDirection: 'row',
        paddingBottom: 60,
      },
    },

    leftColumnWrap: {
      padding: '40px',
      [theme.breakpoints.up(767)]: {
        paddingRight: 50
      },
      [theme.breakpoints.up(960)]: {
        flex: '0 0 33.33%',
        maxWidth: '33.33%',
        paddingRight: 90,
      },
      [theme.breakpoints.up(1220)]: {
        padding: '40px 60px 40px 0'
      },
    },

    rightColumnWrap: {
      [theme.breakpoints.up(960)]: {
        flex: '0 0 66.66%',
        maxWidth: '66.66%',
      },
    },

    rightColumnText: {
      backgroundColor: '#F1F1F1',
      padding: '40px',
    },

    similarProjectsWrap: {
      display: 'none',
      [theme.breakpoints.up(960)]: {
        display: 'block',
        paddingBottom: 120,
      },
    },

    projectSliderWrap: {
      paddingTop: 40,
      display: 'block',
      [theme.breakpoints.up(960)]: {
        display: 'none',
        paddingTop: 0,
      },
    },

    sliderWrap: {
      marginTop: 20,
      [theme.breakpoints.up(767)]: {
        marginTop: 45
      },
    },

    projectSlider: {
      marginTop: 20,
    },

    videoWrap: {
      position: 'relative',
      minHeight: '506px',
      width: '100%',
      cursor: 'pointer',
      [theme.breakpoints.up(767)]: {
        minHeight: '660px',
      },
      '&:before' : {
        '&:hover' : {
        position: 'absolute',
        zIndex: 12,
        content: '"Double click to full screen/maximize"',
        pointerEvent: 'none'
        }
      }
    },

    playerWrap: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },

    video: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: "translate(-50%,-50%)"
    }
  });

const DrainageServicePage = ({classes,toggleQuoteComp,children}) => {
const [isVisible, setIsVisible] = React.useState(false);
function onChange(newVisible) {
    setIsVisible(isVisible === true ? true : newVisible);
  }

return(
  <React.Fragment>
  <SEO
        title="Turf Drainage & Irrigation - Twin View Turf - Producer of Australia’s Best Lawns"
        keywords={[
          `Turf care guides`,
          `care guides`,
          `Turf`,
          `Lawns`,
          `Best Lawns`,
          `Australia’s Best Lawns`,
          `lawn producer`,
        ]}
  />

      {/* HERO SECTION START */}
      <InnerImage 
        image={{className: classes.img, src: DrainageServiceImages.hero}}
      ></InnerImage>

      {/* SERVICES - INTRO SECTION */}
      <ServiceIntroSection
        title="Drainage & Irrigation"
        subTitle="For new and existing fields."
        text="We construct state of the art sports field drainage for peak performance, optimal safety and minimised loss of play."
      ></ServiceIntroSection>

      {/* FEATURE SECTION START */}
      <VisibilitySensor onChange={onChange} partialVisibility={false}>
        <CenterContentMain>
          <div className={classes.videoWrap}>
            <div className={classes.playerWrap}>
              <ReactPlayer url={videoUrl} width="1315px" height="820px" controls muted loop playing={isVisible} className={classes.video} />
            </div>
          </div>
        </CenterContentMain>
      </VisibilitySensor>

      {/* TWO COLUMNS SECTION START */}
        <CenterContentNarrow>
          <Grid
            container
            direction="column"
            className={classes.twoColumnsWrap}
          >
            {/* left column */}
            <Grid
              container
              direction="column"
              className={classes.leftColumnWrap}
            >
              <TitleSmaller
                title="Key Benefits:"
              ></TitleSmaller>

              <ListItems
                  text={listItems}
                ></ListItems>

            </Grid>
            {/* right column */}
            <Grid
              container
              direction="column"
              className={classes.rightColumnWrap}
            >
              {/* right column-text */}
              <Grid
                container
                direction="column"
                className={classes.rightColumnText}
              >
                <TitleSmaller
                  title="State Of The Art Technology"
                ></TitleSmaller>

                <Description
                  descText="We use Trimble GPS technology for unrivalled precision creating straight, clean and evenly spaced drainage trenches. Our GPS guided drainage systems are installed using the latest TrenchIt machinery. Together with our Trimble guidance system we are able to input exact specifications which control trench depths, intervals and quantity of inputs. This eliminates risk of human error and offers greater efficiency."
                ></Description>

                <ThumbImages
                  thumbImage1 = { DrainageServiceImages.thumb1 }
                  thumbImage2 = { DrainageServiceImages.thumb2 }
                  thumbImage3 = { DrainageServiceImages.thumb3 }
                ></ThumbImages>

            </Grid>

            </Grid>
          </Grid>
        </CenterContentNarrow>

      {/* RELATED PROJECTS START */}
      <CenterContentNarrow style={{maxWidth: '1020px'}}>
        
        <Grid
          container
          direction="column"
          className={classes.similarProjectsWrap}
        >
        
          <SectionTitle
            title="Related Projects"
          >
          </SectionTitle>

          <Grid
            container
            className={classes.cardsWrap}
          >
            {projectSliderItems.map((item) => (
              <ProjectCard
                key={item.id}
                cardImage={item.imagePath}
                ctaTitle={item.ctaTitle}
                ctaText={item.ctaText}
                ctaHref={item.ctaHref}
              />
              ))}
          </Grid>

        </Grid>

        {/* MOBILE SLIDER */}
        <div className={classes.projectSliderWrap}>
          <SectionTitle
            title="Similar Projects"
          > 
          </SectionTitle>
          <div
            className={classes.projectSlider}
          >
            <CtaCarouselSlider mediumImage items={projectSliderItems} />
          </div>
        </div>    

        </CenterContentNarrow>

      {/* CTA SECTION START */}
      <CtaBlock title="Planning a field construction?">
        <CTAButton
          inverted
          ctaHref="/contact"
          ctaText="Get a quote"
        />
      </CtaBlock>
    {children}
  </React.Fragment>
)

}

export default withQuoteWizard(withStyles(styles, { withTheme: true })(DrainageServicePage));