import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Image from './Image';


const styles = theme =>
  createStyles({
    thumbImage: {
      width: '100%',
      height: '310px',
      [theme.breakpoints.up(960)]:{
        height: '220px',
      }
    },

    thumbsWrap: {
      maxWidth: 400,
      margin: '0 auto',
      marginTop: 40,
      [theme.breakpoints.up(960)]:{
        marginTop: 25,
        flexDirection: 'row',
        maxWidth: '100%',
      }
    },

    thumb: {
      marginBottom: 30,
      [theme.breakpoints.up(960)]: { 
        marginBottom: 0,
        flex: '0 0 32%',
        maxWidth: '32%',
        marginRight: '2%'

      },

      '&:last-of-type': {
        [theme.breakpoints.up(960)]: {
          marginRight: 0
        }
      },
    }

  });

  const ThumbImages = ({classes,thumbImage1,thumbImage2,thumbImage3}) => (

    <Grid
      container
      direction="column"
      className={classes.thumbsWrap}
    >

    <Grid
      className={classes.thumb}
    >
    {thumbImage1 && (
      <Grid item className={classes.thumbImageWrap}>
          <Image relativePath={thumbImage1} className={classes.thumbImage}></Image>
      </Grid>
    )}
  </Grid>

  <Grid
      className={classes.thumb}
    >
    {thumbImage2 && (
      <Grid item className={classes.thumbImageWrap}>
          <Image relativePath={thumbImage2} className={classes.thumbImage}></Image>
      </Grid>
    )}
  </Grid>

  <Grid
      className={classes.thumb}
    >
    {thumbImage3 && (
      <Grid item className={classes.thumbImageWrap}>
          <Image relativePath={thumbImage3} className={classes.thumbImage}></Image>
      </Grid>
    )}
  </Grid>

  </Grid>


  )
  export default withStyles(styles, { withTheme: true })(ThumbImages);