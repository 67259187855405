import React from 'react';
import classNames from 'classnames';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';


const styles = theme =>
  createStyles({
    listText: {
      fontSize: 20,
      fontFamily: theme.typography.fontFamily,
      marginBottom: 10,
      color: theme.palette.text.primary,
      lineHeight: '22px',
      fontWeight: 300,
      paddingLeft: 10,
      letterSpacing: '0.5px'
    },
    textWrap: {
      paddingLeft: 20,
      marginTop: 0,
      listStyleType: 'square',
    },
    listWrap: {
      maxWidth: 420,
    }

  });

const ListItems = ({
  text,
  classes
}) => {
  const texts = Array.isArray(text) ? text : [text]; // Deal with multiple list items

  return (

    <Grid
      container
      className={classes.listWrap}
    >
        {text && (
          <ul className={classes.textWrap}>
            {texts.map((t2, index) => (
              <li
                key={t2}
                className={classNames(classes.listText, {
                  [classes.lastText]: index === texts.length - 1,
                })}
              >
                {t2}
              </li>
            ))}
          </ul>
        )}
    </Grid>

  );
};

  export default withStyles(styles, { withTheme: true })(ListItems);